import { Center, Grid } from '@lojinha/design-system'
import React from 'react'
import { CarouselItem } from './carousel-item'
import { CarouselDisplayProps } from './types'

export const CarouselDisplay = ({
  id,
  kind,
  name,
  slug,
  cardSize,
  items,
  showLastCard,
  originList,
  onArrowSlideClick,
  onSeeAllClick,
  onRenderItem,
  onItemClick,
}: CarouselDisplayProps) => {
  return (
    <Center>
      <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
        <Grid
          gridColumn={['2 / 24', '2 / 12']}
          display="block"
          htmlAttrs={{
            style: {
              maxWidth: '100%',
            },
          }}
        >
          <CarouselItem
            id={id}
            kind={kind}
            name={name}
            slug={slug}
            items={items}
            cardSize={cardSize}
            originList={originList}
            onArrowSlideClick={onArrowSlideClick}
            onItemClick={onItemClick}
            onRenderItem={onRenderItem}
            onSeeAllClick={onSeeAllClick}
            showLastCard={showLastCard}
          />
        </Grid>
      </Grid>
    </Center>
  )
}
