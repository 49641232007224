import React, { FC } from 'react'
import { ArrowButton, Center, Stack, Text } from '@lojinha/design-system'
import styled from 'styled-components'

const Wrapper = styled(Stack)<{ activated?: boolean }>`
  transition: color 300ms cubic-bezier(0.22, 1, 0.36, 1),
    background-color 300ms cubic-bezier(0.22, 1, 0.36, 1);

  ${({ theme, activated }) => `
    svg {
      path {
        fill: ${activated ? theme.colors.grapeDark : theme.colors.grapeLight};
      }
    }
  `}
`

const TextWrapper = styled(Text)<{ large?: boolean }>`
  max-width: ${({ theme, large }) =>
    large ? theme.space.x256 : theme.space.x160};
`

export const CardContent: FC<{
  text: string
  large?: boolean
  activated?: boolean
}> = ({ text, large, activated }) => (
  <Center px="x24" htmlAttrs={{ style: { position: 'relative' } }}>
    <Wrapper margin="x24" alignItems="flex-start" activated={activated}>
      <TextWrapper
        textSize="x2"
        isBrand
        isBold
        large={large}
        textTransform="uppercase"
        color="grapeLightSupport"
      >
        {text}
      </TextWrapper>
      <ArrowButton />
    </Wrapper>
  </Center>
)
