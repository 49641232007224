import React, { FC } from 'react'
import NextLink from 'next/link'
import { Anchor, AnchorProps } from '@lojinha/design-system'

const isExternalHref = (href: string) => href.match(/https?:\/{2}/)

type LinkProps = AnchorProps & {
  href: string
}

export const Link: FC<LinkProps> = ({ href, ...props }) => {
  if (isExternalHref(href)) {
    return <Anchor {...props} href={href} />
  }

  return (
    <NextLink href={href} as={href}>
      <Anchor {...props} href={href} />
    </NextLink>
  )
}
