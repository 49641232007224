import { beholder } from '@lojinha/beholder'
import { DisplayItems } from '../../modules/page-content/page-content.types'
import { OnItemClickType } from './types'

export const onArrowSlideClick = ({
  name,
  direction = 'right',
}: {
  name: string
  direction: 'left' | 'right'
}) =>
  beholder.shot('structuredEvent', {
    category: 'navigation',
    action: `${direction}_see_more_carousel`,
    label: name,
  })

export const onSeeAllClick = ({
  name,
  isHeader,
}: {
  name: string
  isHeader?: boolean
}) => {
  beholder.shot('structuredEvent', {
    category: 'navigation',
    action: `see_more_products`,
    label: name,
    property: isHeader ? 'ver todos' : 'card',
  })
}

export const onItemClick: OnItemClickType = ({
  sku,
  name,
  positionInList,
  isAvailable,
  price,
  originList,
  user,
}) => {
  beholder.shot('productDetailView', {
    id: sku,
    name,
    positionInList,
    listName: originList,
    isAvailable,
    price,
    user: {
      email: user?.email,
      name: user?.name,
      phoneNumber: user?.phoneNumber,
      city: user?.city,
      state: user?.state,
      country: user?.country,
      zipCode: user?.zipCode,
    },
  })
}

export const onRenderItem = ({
  id,
  name,
  positionInList,
  listName,
  isAvailable,
  price,
}: {
  id: string
  name: string
  positionInList: number
  listName: string
  isAvailable: boolean
  price: number
}) =>
  beholder.shot('itemView', {
    id,
    name,
    positionInList,
    listName,
    isAvailable,
    price,
  })

export const onRenderList = ({
  id,
  name,
  items,
}: {
  id: string
  name: string
  items: DisplayItems[]
}) =>
  beholder.shot('listView', {
    id,
    name,
    items: items.map(item => ({
      ...item,
      price: item.price || 0,
      url: item.seo.url,
    })),
  })

export const getSlicedItems = (items: DisplayItems[]) => items.slice(0, 3)
