import { Stack, Text, theme } from '@lojinha/design-system'
import { normalizeProductUrl, testId } from '@lojinha/helpers'
import React, { FC } from 'react'
import styled from 'styled-components'
import { t } from '../../../dictionary'
import { Link } from '../../link'

const Wrapper = styled(Stack)`
  width: 100%;
  ${theme.media.below('md')} {
    justify-content: space-between;
  }
`

export const Header: FC<{
  name: string
  slug?: string
  onLinkClick?: () => void
}> = ({ name, slug, onLinkClick }) => (
  <Wrapper layout={'horizontal'}>
    <Text
      textTransform="uppercase"
      isBrand
      css={`
        ${theme.media.below('sm')} {
          margin: ${theme.space.x16} 0;
        }
      `}
    >
      {name}
    </Text>
    {slug && (
      <Link
        as="a"
        href={normalizeProductUrl(slug)}
        color="success"
        textSize="x1"
        isBold
        isUppercased
        hasAccessibilitySize
        onClick={onLinkClick}
        {...testId(`header-${name}`)}
        htmlAttrs={{ 'aria-label': `${t.seeAll} ${name}` }}
      >
        {t.seeAll}
      </Link>
    )}
  </Wrapper>
)
