import React, { FC, useState } from 'react'
import styled from 'styled-components'

import waves from '../../assets/masks/waves.svg'

import { Link } from '../link'
import { CardContent } from './card-content'

const Wrapper = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: ${theme.space.x8};
    box-shadow: 0px -4px 18px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    &:hover img {
      background-color: ${theme.colors.grapeDark};
      transform: scale(1.1);
    }
`}
`

const CardBackground = styled.img`
  ${({ theme }) => `
    position: absolute;
    width: 100%;
    height: 100%;

    transition: background-color 300ms cubic-bezier(0.22, 1, 0.36, 1),
      background-image 300ms cubic-bezier(0.22, 1, 0.36, 1),
      transform 300ms;

    background-color: ${theme.colors.grapeLight};
    background-image: url(${waves});
    background-position: center;
    background-size: cover;
`}
`

export const LastCard: FC<{
  text: string
  large?: boolean
  onCardClick?: () => void
  slug?: string
}> = ({ text, large, onCardClick, slug }) => {
  const [activated, setActivated] = useState(false)

  return (
    <Link href={slug || '/'}>
      <Wrapper
        onMouseEnter={() => setActivated(true)}
        onMouseLeave={() => setActivated(false)}
        aria-label={text}
        onClick={onCardClick}
      >
        <CardBackground aria-hidden={true} />
        <CardContent
          text={text}
          large={large}
          activated={activated}
          aria-hidden={true}
        />
      </Wrapper>
    </Link>
  )
}
