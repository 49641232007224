import React, { FC } from 'react'
import Head from 'next/head'

export const PageSeo: FC<{
  url?: string
  seoTitle?: string
  seoDescription?: string
}> = ({ seoTitle = 'Liv Up', seoDescription = '', url = '' }) => (
  <Head>
    <link href={`/${url === 'nosso-cardapio' ? '' : url}`} rel="canonical" />
    <title>{seoTitle}</title>
    <meta name="description" content={seoDescription} />
  </Head>
)
