import React, { FC } from 'react'
import styled from 'styled-components'
import { Box } from '@lojinha/design-system'
import { EmptyState } from '../../components/empty-state'
import { t } from '../../dictionary'
import { PageSeo } from './page-seo'

const EmptyStateWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.space.x120} ${theme.space.x0};
  `
)

interface NoPageComponentProps {
  onButtonClick?: () => void
  buttonText?: string
  text?: string
  title?: string
}

export const NoPageComponent: FC<NoPageComponentProps> = ({
  onButtonClick,
  buttonText,
  text,
  title,
}) => {
  return (
    <Box marginTop={['x56', 'x96']} marginBottom={['x32', 'x48']}>
      <PageSeo />
      <EmptyStateWrapper>
        <EmptyState
          text={text ?? t.emptyPageText}
          buttonText={buttonText || t.emptyPageButton}
          onButtonClick={onButtonClick}
          title={title}
        />
      </EmptyStateWrapper>
    </Box>
  )
}
